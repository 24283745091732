import styled from 'styled-components'

import { device } from '../../utils/screenSizes'
import { Link } from 'gatsby'

export const ArticleFooter = styled.div`
  display: flex;
  width: 100%;
  margin-top: 23px;
  margin-bottom: 30px;
  border-top: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
  padding-top: 12px;
  padding-bottom: 12px;

  a {
    text-decoration: none;
  }

  @media ${device.s} {
    .photoArticle & {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`

export const ArticleFooterSNS = styled.div`
  display: flex;
  margin-right: 16px;

  align-items: flex-end;
  a {
    padding: 8px;
  }

  @media ${device.s} {
    margin-right: 0px;
    .platforms {
      display: none;
    }
  }
  .platforms {
    font-family: 'Neue Haas Grotesk Bloomberg';
    padding-bottom: 8px;
    font-size: 0.81em;
    color: #424242;
    padding-left: 8px;
  }
`

export const ArticleFooterSpace = styled.div`
  diplay: flex;
  flex: 1;
`

export const ArticleFooterText = styled(Link)`
  padding: 15px;
  padding-top: 12px;
  padding-bottom: 10px;
  font-family: 'Neue Haas Grotesk Bloomberg';
  color: ${(props) => (props.grey ? '#606060' : '#424242')};
  font-size: 0.81em;

  @media ${device.s} {
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 14px;
    padding-bottom: 7px;
    font-size: 12px;
  }
`

import React from 'react'

import linkedin from '../../components/menu/articleLinkedin.svg'
import youtube from '../../components/menu/articleYoutube.svg'
import instagram from '../../components/menu/articleInstagram.svg'
import twitter from '../../components/menu/articleTwitter.svg'
import facebook from '../../components/menu/articleFacebook.svg'

import {
  ArticleFooter,
  ArticleFooterText,
  ArticleFooterSpace,
  ArticleFooterSNS
} from './footer.styled'

const ArticleFooterSNSItem = ({ alt, href, src }) => {
  return (
    <a rel="noopener noreferrer nofollow" target="_blank" href={href}>
      <img
        style={{ width: 20, height: 20 }}
        width={20}
        height={20}
        src={src}
        alt={alt}
        loading="lazy"
      />
    </a>
  )
}

export default () => {
  return (
    <ArticleFooter className="articleFooter">
      <ArticleFooterSNS>
        <ArticleFooterSNSItem
          src={facebook}
          alt="facebook"
          href="https://www.facebook.com/asharqnews"
        />

        <ArticleFooterSNSItem
          href="https://twitter.com/Asharqnews"
          src={twitter}
          alt="twitter"
        />
        <ArticleFooterSNSItem
          href="https://www.instagram.com/asharqnews/"
          src={instagram}
          alt="instagram"
        />
        <ArticleFooterSNSItem
          href="https://www.youtube.com/c/asharqnews"
          src={youtube}
          alt="youtube"
        />
        <ArticleFooterSNSItem
          href="https://www.linkedin.com/company/asharqnews/"
          src={linkedin}
          alt="linkedin"
        />
      </ArticleFooterSNS>
      <ArticleFooterSpace />
      <ArticleFooterText to="/ar/about/">عن الشرق</ArticleFooterText>
      <ArticleFooterText to="/ar/terms-conditions/">الشروط</ArticleFooterText>
      <ArticleFooterText to="/ar/privacy-policy/">الخصوصية</ArticleFooterText>
    </ArticleFooter>
  )
}
